import React, { useEffect, useState } from "react";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

import "./Home.css";
import "react-toastify/dist/ReactToastify.css";

import { toast } from "react-toastify";
import { ethers } from "ethers";

import Img1 from "../Images/logo.png";
import Img2 from "../Images/SE.png";

import ABI from "./abi.json";
import ABIUTIL from "./abiutil.json";
const PROVIDER =
  // "https://goerli.infura.io/v3/bd3283c53475479792ed8f68c398a0e9";
  "https://mainnet.infura.io/v3/97d9f5fedfa34db7a15d53259ffe34c2";
const CONTRACT = "0xD2E3b1A4F6a9F4864682AEFb19c7a212f5AEf432";
const CONTRACTUTIL = "0xc1AD74848eFbE20e115A7B63b1d779C26CE22AE1";

export default function Home() {
  const [count, setCount] = useState(1);
  let incrementCount = () => {
    if (count < max) setCount(count + 1);
  };
  let decrementCount = () => {
    if (count > 1) setCount(count - 1);
  };

  const [price, setPrice] = useState(80000000000000000);
  const [total, setTotal] = useState(0);
  const [max, setMax] = useState(10);
  const [connected, setConnected] = useState(false);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    const provider = new ethers.providers.JsonRpcProvider(PROVIDER);
    const ct = new ethers.Contract(CONTRACT, ABI, provider);
    const ctutil = new ethers.Contract(CONTRACTUTIL, ABI, provider);

    setStatus(await ctutil.status());
    setPrice(await ctutil.price_Public());
    setTotal(Number(await ct.totalSupply()));
    setMax(Number(await ctutil.mint_perAdd()));
  };

  const connect = async () => {
    if (!window.ethereum) {
      toast.error("Wallet not found");
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    let m;
    try {
      m = await provider.send("eth_requestAccounts", []);
      m = m[0];
    } catch (e) {
      toast.error(e.message);
      return;
    }

    setConnected(true);
  };

  const mint = async () => {
    if (!window.ethereum) {
      toast.error("Wallet not found");
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    let m;
    try {
      m = await provider.send("eth_requestAccounts", []);
      m = m[0];
    } catch (e) {
      toast.error(e.message);
      return;
    }

    const signer = provider.getSigner();
    const ctutil = new ethers.Contract(CONTRACTUTIL, ABIUTIL, signer);
    if (!(await ctutil.status())) {
      toast.error("Minting Not Active!!");
      return;
    }

    const balance = Number(await provider.getBalance(m));

    if (balance < Number(price) * count) {
      toast.error("Insufficient ETH");
      return;
    }

    if (eval(Number(await ctutil.numberMinted(m)) + count) <= max) {
      await toast.promise(
        new Promise(async (resolve, reject) => {
          try {
            const tx = await ctutil.mint(String(count), m, {
              value: String(price * count),
            });
            const req = await tx.wait();
          } catch (e) {
            console.log(e);
            reject();
          }
          resolve();
        }),
        {
          pending: "Loading",
          success: "Complete",
          error: "Failed!!",
        }
      );
    } else {
      toast.error("Already Minted Max");
      return;
    }
  };

  return (
    <>
      <div className="container-fluid greenbg">
        <div className="container">
          <div className="row align-items-center py-2">
            <div className="col-sm-6 text-md-left text-center">
              <a href="/">
                <img className="logo mr-4 pb-2 pb-sm-0" src={Img1} />
              </a>
            </div>
            <div className="col-sm-6 text-md-right text-center">
              <a href="https://discord.gg/NKSk8KbzpU" target="_blank">
                <i className="mx-2 ico fa-brands fa-discord"></i>
              </a>
              <a href="https://www.instagram.com/sinceeveart" target="_blank">
                <i className="mx-2 ico fa-brands fa-square-instagram"></i>
              </a>
              <a href="https://www.twitter.com/sinceeveart" target="_blank">
                <i className="mx-2 ico fa-brands fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="section-bg">
        <div className="container">
          <div className="row pt-5">
            <div className=" col-md-6 text-center"></div>
            <div className="col-md-6 text-center">
              <img className="logo-1" src={Img2} />
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-6"></div>
            <div className="col-md-6 text-center">
              <div className="mint p-5">
                <h1 className="heading">
                  Evette
                  <br />
                  collection
                </h1>
                <h3 className="text-white sub-heading pt-3">
                  {status ? "Minting Active" : "Minting Not Active"}
                </h3>
                <h3 className="text-white sub-heading">
                  Price: {price / 1e18} ETH
                </h3>
                <h3 className="text-white sub-heading">
                  Max per Address: {max}
                </h3>
                <div className="row justify-content-center align-items-center buttonsborder mt-4">
                  <div className="col-4 p-0">
                    <button className="count" onClick={decrementCount}>
                      <span>-</span>
                    </button>
                  </div>
                  <div className="col-4 p-0">
                    <div className="mx-2">
                      <h3 className="text-center text-white ml-3 mr-3 mb-0">
                        {count}
                      </h3>
                      <span className="maxbtn" onClick={() => setCount(max)}>
                        MAX
                      </span>
                    </div>
                  </div>
                  <div className="col-4 p-0">
                    <button className="count plus" onClick={incrementCount}>
                      <span>+</span>
                    </button>
                  </div>
                </div>
                <h3 className="text-white sub-heading py-3">{total} / 1,000</h3>
                <div className="buttons">
                  {connected ? (
                    <button className="btn-1 mx-3" onClick={() => mint()}>
                      MINT
                      <br />
                      <b>{(price / 1e18) * count} ETH</b>
                    </button>
                  ) : (
                    <button className="btn-1 mx-3" onClick={() => connect()}>
                      Connect
                      <br />
                      <b>Wallet</b>
                    </button>
                  )}{" "}
                  <CrossmintPayButton
                    clientId="e8d2b08e-3ffb-46f8-92b3-379f5ad9fed8"
                    mintConfig={{
                      type: "erc-721",
                      totalPrice: String((price / 1e18) * count),
                      quantity: count,
                    }}
                    className="btn-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row pt-3">
            <div className="col-md-6"></div>
            <div className="col-md-6 text-center">
              <div className="icon icot text-center pb-3">
                <a href="https://discord.gg/NKSk8KbzpU" target="_blank">
                  <i className="px-2 ico1 fa-brands fa-discord"></i>
                </a>
                <a href="https://www.instagram.com/sinceeveart" target="_blank">
                  <i className="px-2 ico1 fa-brands fa-square-instagram"></i>
                </a>
                <a href="https://www.twitter.com/sinceeveart" target="_blank">
                  <i className="px-2 ico1 fa-brands fa-twitter"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
